<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <Toolbar class="p-mb-4">
<!--          <template v-slot:left>-->
          <template #start>
            <div style="font-size: 1.4em;" class="p-mr-3" >{{ $t('Orders') }}</div>
          </template>
          <template #end>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

        <Spinner v-if="isLoading"></Spinner>
        <div v-else-if="!isLoading && !items || !items.length" class="no-data-block">{{ $t('No data available') }}</div>
        <template v-else-if="!isLoading">
          <DesktopDataTable v-for="(date, index) of dates"
                            v-show="datedItems[index].length"
                            :key="index"
                            class="datatable"
                            :dataTableIsLoading="dataTableIsLoading"
                            :tableName="parseDate(date)"
                            :itemsArrayIsEmpty="itemsArrayIsEmpty"
                            :sort="sort"
                            :striped="false"
                            :hightlightTable="isTableHightlight(date)"
                            :showSearchInput="false"
                            :actionButtonsCount="actionButtonsCount"
                            :headers="[
                {name: 'start', title: 'Start', width: '4%', sortable: false},
                {name: 'number', title: 'Number', width: '6%', sortable: false},
                {name: 'customer', title: 'Customer', width: '12%', sortable: false},
                {name: 'order', title: 'Order data', width: '17%', sortable: false},
                {name: 'time', title: 'Time', width: '8%', sortable: false},
                {name: 'car', title: 'Car data', width: '20%', sortable: false},
                {name: 'area_id', title: 'Area', width: '8%', sortable: false},
                // {name: 'responsible', title: 'Res.', width: '4%', sortable: false},
                {name: 'masters', title: 'Masters', width: '12%', sortable: false},
                // {name: 'amount', title: 'Sum', width: '8%', sortable: false},
                {name: 'state', title: 'State', width: '10%', sortable: false},
            ]">
            <template v-slot:body>
              <template v-for="(item, index) of datedItems[index]" :key="index">
                <tr :class="{'updated-item': isItemUpdated(item.id)}">
                  <td :class="{'warning-color':  (+new Date() > +new Date(item.start * 1000)) && item.state === 1 }">{{ formatTime(item.start) }}</td>
                  <td>
                    <div>{{ item.number }}</div>
                  </td>
                  <td>
                    <div v-if="item.customer">
                      <span v-if="item.customer.type === constants.userTypes.legal_entity && item.customer.company_name">{{ item.customer.company_name }} </span>
                      <span v-else>{{ item.customer.first_name }} {{ item.customer.last_name }} </span>
                    </div>
                  </td>
                  <td>
                    <div class="p-d-flex p-ai-center p-flex-wrap">
                      <a href="#" @click.prevent="toggleShowPositionTable(item)" class="p-mr-2">
                        <i style="font-size: 0.9em" class="p-mr-1 pi" :class="item.showPositionsTable ? 'pi-minus': 'pi-plus'"></i>
                        <span class="text">{{ item.name || item.number }}</span>
                      </a>
                    </div>
                    <div class="table-small-text">
                      <span :class="{'order-created-by-customer':item.is_created_by_customer}">
<!--                        <span>{{ $t('Created') }}: </span>-->
                        <span v-if="item.created_at">{{ formatDate(item.created_at) }}, </span>
                        <span v-if="item.creator">
<!--                          <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>-->
                          <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}</span>
                          <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
                        </span>
                      </span>
                    </div>
                  </td>

                  <td>
                    <span v-if="item.time && +item.time !== 0">{{ item.time }} {{ $t('h') }}.</span>
                    <i v-else class="ti-minus"></i>
                  </td>

<!--                  <td>-->
<!--                    <div v-if="item.car?.make?.logo_path" class="p-mr-2">-->
<!--                      <img style="max-width: 40px; max-height: 40px; display: block" :src="settings.apiEndPoint + settings.pathToCarLogosDir + item.car.make.logo_path">-->
<!--                    </div>-->
<!--                  </td>-->
                  <td>
                    <span v-if="item.car" >
                        <i class="ti-car p-mr-1"></i>
                        <span class="p-mr-1" v-if="item.car.make">{{ item.car.make.name }}</span>
                        <span class="p-mr-1" v-if="item.car.model">{{ item.car.model.name }}</span>
                      <div class="table-small-text p-d-flex p-ai-center">
                        <div>
                          <span>{{ item.car.plate_number }}</span>
                          <span v-if="item.car.year">, {{ item.car.year }}</span>
                        </div>
                        <div>
                          <a target="_blank" :href="'https://eteenindus.mnt.ee/public/soidukTaustakontroll.jsf?regMark=' + item.car.plate_number" class="p-p-2">
<!--                          <i class="ti-info-alt"></i>-->
                            <i style="font-size: 0.85rem" class="pi pi-external-link"></i>
                          </a>
                        </div>
                      </div>
                    </span>
                    <i v-else class="ti-minus"></i>
                  </td>
                  <td>
                    <span v-if="item.area?.name">{{ item.area.name }}</span>
                    <i v-else class="ti-minus"></i>
                  </td>
<!--                  <td class="avatar-td">-->
<!--                    <span v-if="item.responsible" class="avatar-wrapper">-->
<!--&lt;!&ndash;                      <router-link :to="{ path: `/employees/${item.responsible.id}` }" target="_blank">&ndash;&gt;-->
<!--&lt;!&ndash;                      <img v-if="item.responsible.profile_img" :src="settings.apiEndPoint + settings.pathToAvatarsDir + item.responsible.profile_img + '.jpg'" :alt="item.responsible.first_name + ' ' + item.responsible.last_name" :title="item.responsible.first_name + ' ' + item.responsible.last_name">&ndash;&gt;-->
<!--                      <img v-if="item.responsible.profile_img" :src="settings.apiEndPoint + settings.pathToAvatarsDir + item.responsible.profile_img" :alt="item.responsible.first_name + ' ' + item.responsible.last_name" :title="item.responsible.first_name + ' ' + item.responsible.last_name">-->
<!--                      <img v-else :src="settings.apiEndPoint + settings.pathToAvatarsDir + 'avatar_placeholder.png'" :alt="item.responsible.first_name + ' ' + item.responsible.last_name" :title="item.responsible.first_name + ' ' + item.responsible.last_name">-->
<!--                      &lt;!&ndash;                      </router-link>&ndash;&gt;-->
<!--                    </span>-->
<!--                  </td>-->
                  <td class="avatar-td">
                    <span v-if="item.masters?.length" class="avatar-wrapper">
                      <span
                          v-for="(master, i) of item.masters"
                          :key="i">
<!--                        <router-link :to="{ path: `/employees/${master.user.id}` }" target="_blank" class="avatar-link">-->
<!--                        <img v-if="master.user?.profile_img" :src="settings.apiEndPoint + settings.pathToAvatarsDir + master.user.profile_img + '.jpg'" :class="{'p-mr-1': item.masters.length > 1 && i !== item.masters.length - 1}" :alt="master.user.first_name + ' ' + master.user.last_name" :title="master.user.first_name + ' ' + master.user.last_name">-->
                        <img v-if="master.user?.profile_img" :src="settings.apiEndPoint + settings.pathToAvatarsDir + master.user.profile_img" :class="{'p-mr-1': item.masters.length > 1 && i !== item.masters.length - 1}" :alt="master.user.first_name + ' ' + master.user.last_name" :title="master.user.first_name + ' ' + master.user.last_name">
                        <img v-else :src="settings.apiEndPoint + settings.pathToAvatarsDir + 'avatar_placeholder.png'" :class="{'p-mr-1': item.masters.length > 1 && i !== item.masters.length - 1}" :alt="master.user.first_name + ' ' + master.user.last_name" :title="master.user.first_name + ' ' + master.user.last_name">

<!--                        </router-link>-->
                      </span>
                    </span>
                    <i v-else class="ti-minus"></i>
                  </td>
<!--                  <td>-->
<!--                    <span>{{ formatCurrency(item.grand_total) }}</span>-->
<!--                  </td>-->
                  <td>
                    <OrderStateButton :item="item"/>
                  </td>
                  <td>
<!--                    <div class="table__actions-column-data">-->
                    <div class="p-d-flex">
<!--                      <ViewButton  @click="openOrderInNewWindow(item.number)"></ViewButton>-->
<!--                      <router-link @click.prevent="viewOrder($event, item.state)" :to="{ path: `/customers/${item.id}` }" target="_blank"><ViewButton :disabled="item.state === 1 || item.state === 3 || item.state === 5"></ViewButton></router-link>-->

<!--                      <EditButton :disabled="(disableEditButton && isItemUpdated(item.id)) || dataTableIsLoading" @click="editItem(item)" />-->
<!--                      <Button class="p-button-raised p-button-success" icon="pi pi-comments" :disabled="(disableEditButton && isItemUpdated(item.id)) || dataTableIsLoading" @click="editStaffComment(item)" />-->
                      <router-link :to="{ path: `/master-orders/${item.number}` }"><ViewButton/></router-link>
                    </div>
                  </td>
                </tr>
                <tr v-show="item.showPositionsTable" class="table-opened-info">
                  <td colspan="20" style="padding:0!important;">
                    <PositionsTable :item="item" :isSpoilerTable="true" :showFinancialData="false"/>
                  </td>
                </tr>
              </template>
            </template>
          </DesktopDataTable>
        </template>

        <OwnPaginator v-show="items?.length"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

<!--        <OrderStaffCommentModal :visible="orderStaffCommentModal" @update-staff-comment="updateOrderStaffComment" @close="closeOrderStaffCommentModal" :item="item"/>-->
			</div>
		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import OrderStateButton from '@/pages/orders/components/OrderStateButton'
import formatMixins from '@/mixins/formatMixins'
import PositionsTable from '@/components/PositionsTable'
import httpMixins from '@/mixins/httpMixins'
import datatableMixins from '@/mixins/datatableMixins'
// import settings from "@/settings";
// import OrderStaffCommentModal from "@/pages/orders/components/OrderStaffCommentModal";

export default {
  mixins: [ formatMixins, httpMixins, datatableMixins ],
  components: { OrderStateButton, PositionsTable },
	data() {
		return {
      item: {},
      orderStaffCommentModal: false,
      openedItemsIds: [],
      dates: [],
      datedItems: [],
      fieldsExpand: 'customer,creator,masters,positions,positions.master,consumable,car,area,recommendation_history,state_history,comments_history,parentOffer,parentOrder',
      apiLink: 'order/get-employee-orders',
      routePath: '/employee-orders',
      sort: {
        column: 'start',
        direction: '',
      },
		}
	},
  // watch: {
  //   '$store.state.firstLayerIsOpened'() {
  //     if (!this.$store.state.firstLayerIsOpened) {
  //       if (this.orderStaffCommentModal) {
  //         this.closeOrderStaffCommentModal()
  //       }
  //     }
  //   },
  // },
  mounted() {
    // if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getItems()
  },
  methods: {
    updateOrderStaffComment(obj) {
      if (!obj) return false
      this.updatedItemId = obj.order_id
      const foundOrder = this.items?.find(i => i.id === obj.order_id)
      if (foundOrder) {
        foundOrder.staff_comment = obj.staff_comment
        foundOrder.comments_history?.push({
          type: 2,
          comment: obj.staff_comment,
          created_at: Date.now() / 1000,
          creator: {
            first_name: this.$store.state.user?.first_name, last_name: this.$store.state.user?.last_name},
        })
      }
    },
    // editStaffComment(order) {
    //   if (!order) return
    //
    //   this.item = {
    //     order_id: order.id,
    //     staff_comment: order.staff_comment,
    //   }
    //   this.orderStaffCommentModal = true
    //   this.$store.commit('openRequiredAppLayer')
    // },
    // closeOrderStaffCommentModal() {
    //   this.item = {}
    //   this.orderStaffCommentModal = false
    //   this.$store.commit('closeRequiredAppLayer')
    // },
    isTableHightlight(date) {
      if (!date) return false

      const now = new Date()
      date = new Date(date)
      return date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()
    },
    async getItems(showSpinner = false) {
      // console.log('get orders')
      this.dataTableIsLoading = true
      this.disableEditButton = true
      if (showSpinner) {
        this.isLoading = true
      }
      try {
        // const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          // method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          // data: {}
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']

          this.items = data
          // console.log(data)

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }

          this.constructOrdersTable()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
      }
    },
    constructOrdersTable() {
      if (!this.items?.length) return
      let dates = []
      this.items.forEach(item => {
        const date = new Date(item.start * 1000)
        const day = String(date.getDate()).length === 1 ? '0' + String(date.getDate()) : date.getDate()
        const month = String(date.getMonth() + 1).length === 1 ? '0' + String(date.getMonth() + 1) : date.getMonth() + 1
        const year = date.getFullYear()
        const fullDate = `${year}-${month}-${day}`
        if (dates.indexOf(fullDate) === -1) {
          dates.push(fullDate)
        }
      })

      if (this.openedItemsIds.length) {
        this.items.forEach(item => {
          if (this.openedItemsIds.indexOf(item.id) !== -1) {
            item.showPositionsTable = true
          }
        })
      }

      this.dates = dates.sort((a, b) => +new Date(a) - +new Date(b))
      // this.dates = dates.sort((a, b) => new Date(a.start) - new Date(b.start))

      let i = 0
      for (let date of this.dates) {
        this.datedItems[i] = this.items.filter(item => {
          const itemDate = new Date(item.start * 1000)
          return +itemDate.getFullYear() === Number(date.substr(0, 4)) &&
              +itemDate.getMonth() + 1 === Number(date.substr(5, 2)) &&
              +itemDate.getDate() === Number(date.substr(8, 2))
        }).sort((a, b) => a.start - b.start)
        i++
      }
    },
    parseDate(date) {
      const parsedDate = new Date(date)
      const parsedDateYear = parsedDate.getFullYear()
      const parsedDateMonth = parsedDate.getMonth()
      const parsedDateDay = parsedDate.getDate()
      const parsedDay = new Date(parsedDateYear, parsedDateMonth, parsedDateDay)

      const todayDate = new Date()
      const todayYear = todayDate.getFullYear()
      const todayMonth = todayDate.getMonth()
      const todayDay = todayDate.getDate()
      const today = new Date(todayYear, todayMonth, todayDay)

      const yesterday = new Date(todayYear, todayMonth, todayDay - 1)
      const tomorrow = new Date(todayYear, todayMonth, todayDay + 1)

      let day = ''

      if (+parsedDay === +today) {
        day = ' - ' + this.$t('Today')
      } else if (+parsedDay === +yesterday) {
        day = ' - ' + this.$t('Yesterday')
      } else if (+parsedDay === +tomorrow) {
        day = ' - ' +  this.$t('Tomorrow')
      }

      return this.formatDay(parsedDate / 1000) + ' ' + day
    },
	},
  computed: {
    actionButtonsCount() {
      return 1
    },
  }
}
</script>

<style scoped lang="scss">
.no-data-block {
  padding: 12vh 0;
  //height: 100%;
  text-align: center;
}
</style>